@media screen and (max-width: 767px) {
	.opux-table.stackable > tbody,
	.opux-table.stackable > tbody > tr,
	.opux-table.stackable > tbody > tr > td,
	.opux-table.stackable > thead > tr > th {
		width: auto !important;
		display: block !important;
	}
}

.opux-expander-unstyled {
  height: auto !important;
}

.opux-expander-unstyled > .opux-expander-title {
  padding: 0;
  min-height: 0;
}

.opux-expander-unstyled > .opux-expander-title > a {
	width: 100%;
}

.opux-expander-title {
  padding-right: 30px;
}

.opux-expander.lazy {
	height: auto !important;
}

.opux-tooltip-tip {
	z-index: 1100;
}

.opux-tooltip-tip > div {
	padding-right: 5px;
	overflow-x: hidden;
	overflow-y: auto;
}

.opux-list.horizontal > li {
	display: inline-block;
	padding-top: 0;
	padding-bottom: 0;
	border: 0;
}

.opux-list.horizontal > li::before {
	content: '|';
	color: rgba(0,0,0,.15);
	padding-right: 8px;
	padding-left: 8px;
}

.opux-list.horizontal > li:first-child::before {
	content: '';
	padding-left: 0;
	padding-right: 0;
}

a > dt {
	color: inherit !important;
}

.opux-btn {
  margin-left: 0;
}

.opux-btn + .opux-btn {
  margin-top: inherit;
  margin-left: 0;
}

.opux-paginate .opux-btn + .opux-btn {
	margin-left: 6px;
}

.opux-pikaday {
	z-index: 1100;
}
