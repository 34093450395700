html {
  overflow: visible;
  overflow-x: hidden;
}

html.opux-modal-open {
  overflow: hidden;
}

.op-navi-link.active, .op-navi-link.active > i {
  color: #ff6a10 !important;
}

.op-navi-level-2 .op-navi-link > .opux-icon {
  display: inline !important;
}

.op-sidebar-content .portlet-body > * {
  max-height: 62px;
}

.op-content-tile-title > button {
  margin-left: 10px !important;
  margin-top: 10px;
}

.op-navigation {
  border-bottom: none !important;
}

#op-topbar-tabs {
  display: table !important;
}

a {
  outline: 0 !important;
}

@media screen and (max-width: 611px) {
  .opux-btn, .opux-btn.opux-btn-icon-text.opux-btn-icon-text, .opux-btn.opux-btn-mini {
    min-width: inherit;
  }

  #op-login-full-page button {
    min-width: 100%;
    margin-top: 10px;
  }

  #op-login-full-page {
    padding-top: 1rem;
  }
}

.op-content-tile-title {
  display: flex !important;
  align-items: center;
}

.op-content-tile-title > button {
  max-height: 30px;
}
