@media (min-width: 980px) {
  #op-right-sidebar.op-sidebar:not(.op-sidebar-open) {
    width: 60px;
  }

  #op-right-sidebar:not(.op-sidebar-open) .op-sidebar-module {
    width: 280px;
  }

  #op-right-sidebar:not(.op-sidebar-open) .op-sidebar-module.language a {
    display: none;
  }

  #op-right-sidebar:not(.op-sidebar-open) .op-sidebar-module.language span {
    width: 60px;
    background-color: #fff;
    border-right-width: 0;
  }

  #op-content:not(.op-sidebar-open) {
    margin-right: 60px;
  }
}
