.draggable {
  cursor: move !important;
}

.draggable * {
  cursor: move !important;
}

.in-drag {
  z-index: 1100;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.clear-both {
  clear: both;
}

.clickable {
  cursor: pointer;
}

.fluid {
	width: 100%;
}
